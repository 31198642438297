<script setup lang="ts">
import { ref } from "vue"

const props = defineProps<{
    action: (payload?: any) => Promise<any>
}>()

const emit = defineEmits<{
    (e: "close"): void
    (e: "success", result: any): void
    (e: "error", error: any): void
}>()

const loading = ref<boolean>(false)

const handleAction = async (payload?: any) => {
    loading.value = true
    try {
        const result = await props.action(payload)
        emit("success", result)
    } catch (error) {
        console.log(error)
        emit("error", error)
    } finally {
        loading.value = false
    }
}
</script>

<template>
    <slot v-bind="{ loading, action: handleAction }" />
</template>

<style scoped></style>
