<script setup lang="ts">
import useValidate from "@vuelidate/core"
import { email, helpers, minLength, required } from "@vuelidate/validators"
import type { AxiosError } from "axios"
import { computed, reactive } from "vue"

import InfoComp from "@/components/info/InfoComp.vue"
import EmailSentMessage from "@/components/lottie/EmailSentMessage.vue"

import type { RegistrationFormData } from "@/interfaces/auth.interface"
import { type AxiosErrorReturn, ERROR_CODES } from "@/interfaces/basic.interface"

import { useAuthStore } from "@/stores/authStore"
import { useConfigStore } from "@/stores/configStore"

import { oldEnough } from "@/composables/validators/OldEnough"
import { toOld } from "@/composables/validators/ToOld"

const authStore = useAuthStore()
const configStore = useConfigStore()

const registrationFormData = reactive<RegistrationFormData>({
    devPassword: "",
    dob: "",
    email: "",
    firstname: "",
    username: ""
})

const registrationProcess = reactive({
    emailExists: false,
    step: 1,
    usernameExists: false
})

const usernamePattern = helpers.regex(/^[a-zA-Z0-9_]+$/)

const rules = computed(() => {
    return {
        devPassword: {},
        dob: {
            // eslint-disable-next-line
            required,
            // eslint-disable-next-line
            oldEnough: helpers.withMessage("form.error.oldEnough", oldEnough({ year: 14 })),
            toOld: helpers.withMessage("form.error.toOld", toOld({ year: 30 }))
        },
        email: { email, required },
        firstname: { required },
        username: { minLength: minLength(4), required, usernamePattern }
    }
})

const $v = useValidate(rules, registrationFormData)
const register = async () => {
    await $v.value.$validate()
    if (!$v.value.$error) {
        try {
            registrationProcess.usernameExists = false
            registrationProcess.emailExists = false
            await authStore.register({ ...registrationFormData })
            registrationProcess.step = 2
        } catch (error) {
            const code = ((error as AxiosError).response?.data as AxiosErrorReturn).status?.code
            if (code === ERROR_CODES.usernameExists) registrationProcess.usernameExists = true
            if (code === ERROR_CODES.emailExists) registrationProcess.emailExists = true
        }
    }
}
</script>
<template>
    <div
        class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm animate__animated animate__fadeIn animate__delay-1s">
        <form v-if="registrationProcess.step === 1" method="POST" @submit.prevent="register">
            <h2
                class="mb-10 text-center text-2xl font-satoshiBold leading-9 tracking-tight text-gray-900">
                {{ $t("registerView.title") }}
            </h2>
            <div class="space-y-6">
                <div class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                    <div>
                        <label
                            for="first-name"
                            class="block text-sm font-satoshiSemibold leading-6 text-gray-900"
                            >{{ $t("registerView.labels.firstname") }}</label
                        >
                        <div>
                            <input
                                id="first-name"
                                v-model="registrationFormData.firstname"
                                type="text"
                                name="first-name"
                                autocomplete="given-name"
                                :placeholder="$t('registerView.labels.firstname')"
                                class="w-full block rounded-md border-0 bg-slate-100 py-3 px-2 text-gray-900 shadow-sm focus:outline-none placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                        </div>
                        <info-comp
                            v-if="$v.firstname.$error"
                            class="error"
                            :complex="$v.firstname.$errors[0]" />
                    </div>
                    <div>
                        <label
                            for="username"
                            class="block text-sm font-satoshiSemibold leading-6 text-gray-900"
                            >{{ $t("registerView.labels.username") }}</label
                        >
                        <div>
                            <input
                                id="username"
                                v-model="registrationFormData.username"
                                type="text"
                                name="username"
                                autocomplete="username"
                                :placeholder="$t('registerView.labels.username')"
                                class="w-full block rounded-md focus:outline-none border-0 bg-slate-100 py-3 px-2 text-gray-900 dark:text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                        </div>
                        <info-comp
                            v-if="registrationProcess.usernameExists"
                            class="error"
                            :message="'errorCodes.' + ERROR_CODES.usernameExists" />
                        <info-comp
                            v-if="$v.username.$error"
                            class="error"
                            :complex="$v.username.$errors[0]" />
                    </div>
                </div>
                <div>
                    <label
                        for="email"
                        class="block text-sm font-satoshiMedium leading-6 text-gray-900"
                        >{{ $t("registerView.labels.email") }}</label
                    >
                    <div>
                        <input
                            id="email"
                            v-model="registrationFormData.email"
                            name="email"
                            type="email"
                            autocomplete="email"
                            placeholder="max@mustermann.de"
                            class="w-full block rounded-md focus:outline-none border-0 bg-slate-100 py-3 px-2 text-gray-900 shadow-sm dark:text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                    </div>
                    <info-comp
                        v-if="registrationProcess.emailExists"
                        class="error"
                        :message="'errorCodes.' + ERROR_CODES.emailExists" />
                    <info-comp
                        v-if="$v.email.$error"
                        class="error"
                        :complex="$v.email.$errors[0]" />
                </div>
                <div>
                    <div class="flex items-center justify-between">
                        <label
                            for="dob"
                            class="block text-sm font-satoshiMedium leading-6 text-gray-900"
                            >{{ $t("registerView.labels.dob") }}</label
                        >
                    </div>
                    <div>
                        <input
                            id="dob"
                            v-model="registrationFormData.dob"
                            name="dob"
                            type="date"
                            autocomplete="birthday"
                            class="block w-full rounded-md focus:outline-none border-0 bg-slate-100 py-3 px-2 text-gray-900 shadow-sm dark:text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                    </div>
                    <info-comp
                        v-if="$v.dob.$error"
                        class="error"
                        :message="$v.dob.$errors[0].$message as string" />
                </div>
                <div v-if="!configStore.isProduction">
                    <div class="flex items-center justify-between">
                        <label
                            for="devPassword"
                            class="block text-sm font-satoshiMedium leading-6 text-gray-900 dark:text-gray-900"
                            >{{ $t("registerView.labels.devPassword") }}</label
                        >
                    </div>
                    <div>
                        <input
                            id="devPassword"
                            v-model="registrationFormData.devPassword"
                            name="devPassword"
                            placeholder="Dev-Passwort"
                            type="password"
                            class="block w-full rounded-md focus:outline-none border-0 bg-slate-100 py-3 px-2 text-gray-900 dark:text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        class="duration-300 hover:scale-105 flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-satoshiSemibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                        {{ $t("basic.next") }}
                    </button>
                </div>
            </div>
        </form>
        <div v-else class="text-center py-4 md:-ml-10 2xl:-ml-44 mt-10 rotate-[7deg]">
            <EmailSentMessage :email="registrationFormData.email" />
        </div>
    </div>
</template>

<i18n lang="json">
{
    "de": {
        "registerView": {
            "title": "Kostenlos Registrieren",
            "labels": {
                "firstname": "Vorname",
                "username": "Benutzername",
                "email": "E-Mail-Adresse",
                "dob": "Geburtstag",
                "devPassword": "Dev-Passwort"
            }
        }
    }
}
</i18n>

<style scoped></style>
