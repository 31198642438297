import { type FirebaseApp, initializeApp } from "firebase/app"
import { getMessaging, getToken, type Messaging, onMessage } from "firebase/messaging"
import { DateTime } from "luxon"
import { defineStore } from "pinia"
import { ref, watch } from "vue"

import { FIREBASE_CONFIG, VAPID_KEY } from "@/interfaces/notification.interface"
import type { Listener } from "@/interfaces/wall.interface"

import NotificationHttpService from "@/services/Notification.http.service"
import { createSocketConnection } from "@/services/Socket.service"

import { useAuthStore } from "@/stores/authStore"
import { useUserStore } from "@/stores/userStore"

export const useNotificationStore = defineStore("notification", () => {
    const authStore = useAuthStore()
    const userStore = useUserStore()

    /*------Firebase------*/

    const app = ref<FirebaseApp>(initializeApp(FIREBASE_CONFIG))
    const messaging = ref<Messaging>(getMessaging(app.value))
    const token = ref<string>()

    const registerPushToken = async () => {
        token.value = await getToken(getMessaging(), {
            serviceWorkerRegistration: await navigator.serviceWorker.register("/firebase.js"),
            vapidKey: VAPID_KEY
        })

        onMessage(messaging.value, (payload) => {
            console.log("Message received. ", payload)
        })
    }

    const checkNotificationPermission = async () => {
        if (window.Notification.permission === "granted") {
            await registerPushToken()
        }
    }

    const getPushNotificationPermission = async () => {
        if ((await authStore.isAuth()) && !token.value) {
            try {
                const permission = await window.Notification.requestPermission()
                if (permission === "granted") {
                    await registerPushToken()
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    watch(
        () => token.value,
        async () => {
            if (token.value) {
                await NotificationHttpService.putPushToken(token.value)
            }
        }
    )

    /*------SOCKET------*/

    const listener = ref<Listener[]>([])
    const lastConnect = ref<number>(DateTime.now().toSeconds())

    const addListener = (newListener: Listener) => {
        listener.value.push(newListener)
        if (socket.value) {
            socket.value.on(newListener.key, newListener.function)
        }
    }

    const removeListener = (oldListener: Listener) => {
        listener.value = listener.value.filter(
            (tempListener) => tempListener.key !== oldListener.key
        )
        if (socket.value) {
            socket.value.off(oldListener.key, oldListener.function)
        }
    }

    const registerListener = () => {
        if (socket.value) {
            listener.value.forEach((listener) => {
                socket.value!.on(listener.key, listener.function)
            })
            lastConnect.value = DateTime.now().toSeconds()
        }
    }

    const { socket, connected, closeSocket, initializeSocket } = createSocketConnection(
        "chat",
        {},
        registerListener
    )

    watch(
        () => authStore.authToken,
        async () => {
            closeSocket()
            if (authStore.authToken && userStore.user.active) {
                await initializeSocket()
            }
        },
        {}
    )

    return {
        addListener,
        checkNotificationPermission,
        closeSocket,
        connected,
        getPushNotificationPermission,
        initializeSocket,
        lastConnect,
        removeListener,
        socket,
        token
    }
})
