import { createRouter, createWebHistory } from "vue-router"

import BetaVersion from "@/components/atoms/hints/BetaVersion.vue"
import FooterComp from "@/components/navigation/FooterComp.vue"

import { useAuthStore } from "@/stores/authStore"
import { useUserStore } from "@/stores/userStore"

import LoginView from "@/views/auth/LoginView.vue"
import RegisterView from "@/views/auth/RegisterView.vue"
import LandingView from "@/views/LandingView.vue"

const AccountParkedView = () => import("@/views/AccountParkedView.vue")
const ResetPasswordView = () => import("@/views/auth/ResetPasswordView.vue")
const AuthView = () => import("@/views/AuthView.vue")
const FeedView = () => import("@/views/FeedView.vue")
const DashboardView = () => import("@/views/intern/DashboardView.vue")
const ImpressumView = () => import("@/views/legal/ImpressumView.vue")
const PrivacyView = () => import("@/views/legal/PrivacyView.vue")
const MessengerView = () => import("@/views/MessengerView.vue")
const NotFoundView = () => import("@/views/NotFoundView.vue")
const ProfileView = () => import("@/views/ProfileView.vue")
const GeneralSettingsView = () => import("@/views/settings/GeneralSettingsView.vue")
const NotificationSettingsView = () => import("@/views/settings/NotificationSettingsView.vue")
const SettingsView = () => import("@/views/SettingsView.vue")
const Test = () => import("@/views/test.vue")
const VisitorsView = () => import("@/views/VisitorsView.vue")
const FooterMenu = () => import("@/components/navigation/FooterMenu.vue")
const NavBar = () => import("@/components/navigation/NavBar.vue")
const SideBar = () => import("@/components/navigation/SideBar.vue")

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            components: {
                default: LandingView,
                footer: FooterComp,
                hint: BetaVersion
            },
            meta: { offline: true },
            name: "landing",
            path: "/"
        },
        {
            children: [
                {
                    component: LoginView,
                    name: "login",
                    path: ""
                },
                {
                    component: RegisterView,
                    meta: { auth: false },
                    name: "register",
                    path: "register"
                },
                {
                    component: ResetPasswordView,
                    meta: { auth: false },
                    name: "password",
                    path: "password/:token/:username/:new?"
                }
            ],
            components: {
                default: AuthView,
                footer: FooterComp,
                hint: BetaVersion
            },
            meta: {
                auth: false,
                offline: true
            },
            name: "auth",
            path: "/auth"
        },
        {
            components: {
                default: FeedView,
                footer: FooterMenu,
                navBar: NavBar,
                sideBar: SideBar
            },
            meta: { auth: true, offline: true },
            name: "feed",
            path: "/feed"
        },
        {
            alias: "/messenger/:username",
            components: {
                default: MessengerView,
                navBar: NavBar,
                sideBar: SideBar
            },
            meta: { auth: true },
            name: "messenger",
            path: "/messenger"
        },
        {
            components: {
                default: DashboardView,
                footer: FooterMenu,
                navBar: NavBar,
                sideBar: SideBar
            },
            meta: { area: 2000, auth: true },
            name: "dashboard",
            path: "/intern/dashboard"
        },
        {
            components: {
                default: ProfileView,
                footer: FooterMenu,
                navBar: NavBar,
                sideBar: SideBar
            },
            meta: { auth: true },
            name: "profile",
            path: "/profile/:username",
            props: true
        },
        {
            components: {
                default: VisitorsView,
                footer: FooterMenu,
                navBar: NavBar,
                sideBar: SideBar
            },
            meta: { auth: true },
            name: "visitors",
            path: "/visitors"
        },
        {
            children: [
                {
                    component: GeneralSettingsView,
                    name: "generalSettings",
                    path: ""
                },
                {
                    component: NotificationSettingsView,
                    name: "notificationSettings",
                    path: "notifications"
                }
            ],
            components: {
                default: SettingsView,
                footer: FooterMenu,
                navBar: NavBar,
                sideBar: SideBar
            },
            meta: { auth: true },
            name: "settings",
            path: "/settings"
        },
        {
            components: {
                default: NotificationSettingsView,
                footer: FooterMenu,
                navBar: NavBar,
                sideBar: SideBar
            },
            meta: { auth: true },
            name: "notificationSettings",
            path: "/settings/notifications"
        },
        {
            components: {
                default: ImpressumView,
                footer: FooterComp,
                hint: BetaVersion
            },
            meta: { offline: true },
            name: "impressum",
            path: "/impressum"
        },
        {
            components: {
                default: PrivacyView,
                footer: FooterComp,
                hint: BetaVersion
            },
            meta: { offline: true },
            name: "privacy",
            path: "/privacy"
        },
        {
            component: AccountParkedView,
            meta: { auth: true, parked: true },
            name: "accountParked",
            path: "/accountParked"
        },
        {
            component: Test,
            name: "test",
            path: "/test"
        },
        {
            alias: "/404",
            component: NotFoundView,
            name: "notfound",
            path: "/:pathMatch(.*)*"
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    const userStore = useUserStore()

    if (!to.meta.offline) {
        const auth = await authStore.isAuth()

        if (to.meta.auth) {
            //Wenn nicht eingeloggt zum login
            if (!auth) {
                return next({ name: "login" })
            }

            // Ist eine Berechtigung erforderlich?
            if (to.meta.area && !authStore.checkAuthArea(to.meta.area as number)) {
                return next({ name: "feed" })
            }

            if (to.meta.parked && userStore.user.active) {
                return next({ name: "feed" })
            }

            if (!to.meta.parked && !userStore.user.active) {
                return next({ name: "accountParked" })
            }

            //todo ist ist sie sobald man sich einloggt.
            // Ist die E-Mail-Adresse bestätigt?
            // if (userStore.user?.registerStep === 0) {
            //     return next({ name: "confirmEmail" })
            // }

            return next()
        } else if (to.meta.auth === false) {
            console.log("auth", auth)
            return auth ? next({ name: "feed" }) : next()
        }
    }

    return next()
})

// Slide Animation
const history: { path: string; direction: string }[] = []

router.afterEach((to, from) => {
    if (to.path === history[history.length - 2]?.path) {
        to.meta.transition =
            history[history.length - 2]?.direction === "slide-right" ? "slide-left" : "slide-right"
        history.pop()
    } else {
        to.meta.transition =
            to.path.split("/").length < from.path.split("/").length ? "slide-right" : "slide-left"
        history.push({ direction: to.meta.transition as string, path: to.path })
    }
})

export default router
