import type { User } from "@/interfaces/user.interface"

export interface AxiosResponseReturn<T> {
    httpCode: number
    status: {
        code: number
        message: string
    }
    data: T
    user: User
}

export interface AxiosErrorReturn {
    status: {
        code: number
        message: string | object
    }
}

export const ERROR_CODES = {
    emailExists: 1724496971,
    usernameExists: 1724496590
} as const

export interface ModalProps {
    open: boolean
    header?: string
    cancelLabel?: string
    approveLabel?: string
    loading?: boolean
}

export const PASTEL_COLORS = ["pink", "orange", "yellow", "green", "blue", "purple"] as const
