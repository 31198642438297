import { io, Socket as ClientSocket } from "socket.io-client"
import { type Ref, ref, type UnwrapRef } from "vue"

import { useAuthStore } from "@/stores/authStore"
import { useConfigStore } from "@/stores/configStore"
import { useUserStore } from "@/stores/userStore"

export function createSocketConnection(
    namespace: string,
    eventHandlers: { [key: string]: (data: any) => void },
    onConnect?: () => void
): {
    connected: Ref<UnwrapRef<boolean>>
    initializeSocket: () => Promise<void>
    closeSocket: () => void
    socket: Ref<UnwrapRef<ClientSocket<any, any> | null>>
} {
    const authStore = useAuthStore()
    const configStore = useConfigStore()
    const userStore = useUserStore()

    const socket = ref<ClientSocket<any, any> | null>(null)
    const connected = ref(false)

    const initializeSocket = async () => {
        return new Promise((resolve: (value?: any) => void) => {
            if (!socket.value && authStore.authToken && userStore.user.active) {
                socket.value = io(`${configStore.projectURL}${namespace}`, {
                    auth: {
                        token: authStore.authToken
                    },
                    randomizationFactor: 0.5,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 2000,
                    reconnectionDelayMax: 10000,
                    transports: ["websocket", "polling"],
                    withCredentials: true
                }) as ClientSocket

                socket.value.on("connect", () => {
                    connected.value = true
                    registerListeners()
                    if (onConnect) onConnect()
                    resolve()
                })

                socket.value.on("connect_error", async (error) => {
                    connected.value = false
                    console.error("Socket connection error:", error)

                    if (error.message === "Unauthorized") {
                        console.error("Authentication error, please log in again.")
                        await authStore.logout()
                    }
                })

                socket.value.on("disconnect", () => {
                    connected.value = false
                    if (socket.value && !socket.value.connected) {
                        setTimeout(() => {
                            if (socket.value) socket.value.connect()
                        }, 2000)
                    }
                })

                socket.value.on("exception", (error: Error) => {
                    console.error("Error:", error)
                })
            } else {
                resolve()
            }
        })
    }

    const registerListeners = () => {
        if (socket.value) {
            socket.value!.removeAllListeners()
            Object.keys(eventHandlers).forEach((event) => {
                socket.value!.on(event, eventHandlers[event])
            })
        }
    }

    const closeSocket = () => {
        try {
            if (socket.value) {
                socket.value.disconnect()
                socket.value = null
                connected.value = false
            }
        } catch (e) {
            console.log(e, "socketCloseError")
        }
    }

    return {
        closeSocket,
        connected,
        initializeSocket,
        socket
    }
}
