<script setup lang="ts">
import { Capacitor } from "@capacitor/core"
</script>

<template>
    <div
        v-if="!Capacitor.isNativePlatform()"
        class="fixed left-0 right-0 top-0 flex flex-col md:flex-row flex-wrap text-center overflow-hidden justify-center items-center bg-red-600 text-white text-xl font-bold z-50 gap-2 py-2">
        <div>Achtung! Das ist eine Entwicklungsversion! Nicht anmelden!</div>
        <div class="flex flex-row gap-4">
            <RouterLink class="text-white underline" :to="{ name: 'impressum' }">
                Impressum
            </RouterLink>
            <RouterLink class="text-white underline" :to="{ name: 'privacy' }">
                Datenschutz
            </RouterLink>
        </div>
    </div>
</template>

<style scoped></style>
