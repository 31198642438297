<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue"
import { ref } from "vue"

import InlineLoader from "@/components/atoms/loader/InlineLoader.vue"
import LogoComp from "@/components/atoms/logo/LogoComp.vue"

const show = ref<boolean>(false)

const randomPic = Math.floor(1 + Math.random() * 7)

setTimeout(() => {
    show.value = true
}, 250)
</script>

<template>
    <div
        class="bg-cover bg-center w-screen h-screen relative -mt-16"
        :style="{
            backgroundImage: 'url(\'/assets/images/persons/bg-' + randomPic + '.webp\')'
        }">
        <TransitionRoot
            :show="show"
            enter="transition-opacity duration-1000"
            enter-from="opacity-0"
            enter-to="opacity-100"
            class="flex flex-col gap-8 justify-center items-center w-screen h-screen overflow-hidden bg-gradient-to-br from-pastel-blue-500/25 via-pastel-pink-500/15 to-pastel-yellow-500/10 backdrop-blur backdrop-brightness-75">
            <LogoComp force="dark" classes="w-80 max-w-1/2 z-10" />
            <router-link :to="{ name: 'login' }" class="btn bg-pastel-pink-500 z-10">
                {{ $t("auth.login.title") }}
            </router-link>
        </TransitionRoot>
    </div>
</template>

<i18n lang="json">
{
    "de": {
        "landingView": {}
    }
}
</i18n>

<style scoped></style>
