<template>
    <div ref="lottieContainer" :class="classes"></div>
</template>

<script setup lang="ts">
const lottie = await import("lottie-web-light/build/player/lottie_light")
import { onBeforeUnmount, onMounted, ref } from "vue"

const props = withDefaults(
    defineProps<{
        animationData?: any
        animationPath?: string
        loop?: boolean
        autoplay?: boolean
        classes?: string
    }>(),
    {
        autoplay: true,
        classes: "w-full h-full",
        loop: true
    }
)

const lottieContainer = ref<HTMLDivElement>()
let lottieInstance: any = null
onMounted(() => {
    if (lottieContainer.value) {
        lottieInstance = lottie.loadAnimation({
            animationData: props.animationData,
            autoplay: props.autoplay,
            container: lottieContainer.value,
            loop: props.loop,
            path: props.animationPath
        })
    }
})

onBeforeUnmount(() => {
    if (lottieInstance) {
        lottieInstance.destroy()
    }
})
</script>

<style scoped></style>
