export interface NotificationType {
    id: string
    category?: string
    title?: string
    slug: string
    description?: string
    defaultValue: boolean
}

export interface NotificationSetting {
    id?: string
    userId?: string
    notificationId: string
    value: boolean
}

export interface SettingsTypesResponse {
    notificationTypes: NotificationType[]
    notificationSettings: NotificationSetting[]
}

export interface SettingsResponse {
    notificationSettings: NotificationSetting[]
}

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyCCdwtAItpu3OjHNOmJK9rfSLaNxmBUPy0",
    appId: "1:563789462992:web:bf8d1e7909d10a932b4dd5",
    authDomain: "reavo-app.firebaseapp.com",
    messagingSenderId: "563789462992",
    projectId: "reavo-app"
}

export const VAPID_KEY =
    "BOUCOELvt9QD0nmn0_r6QRqrNCtmS8G1voKZ8UDW8RWmcpdPcUgaVlWSlsos8fCs875Gz_tjfoCC8nTRQRtEcwQ"
