<script setup lang="ts">
import logo from "@/assets/images/logo/reavo_full.svg?component"
import logoDark from "@/assets/images/logo/reavo_full_dark.svg?component"

import { useConfigStore } from "@/stores/configStore"

defineProps<{
    classes?: string
    force?: "dark" | "light"
}>()

const configStore = useConfigStore()
</script>

<template>
    <div :class="classes ?? 'w-80 max-w-3/4'" @click="$router.push({ name: 'landing' })">
        <logo v-if="(!configStore.isDarkMode && force !== 'dark') || force === 'light'" />
        <logoDark v-else />
    </div>
</template>

<style scoped></style>
