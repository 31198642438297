import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { createApp } from "vue"
import VueMatomo from "vue-matomo"

import App from "./App.vue"
import router from "./router"

import "./assets/css/index.scss"
import { i18n } from "@/composables/i18n"

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
    .use(i18n)
    .use(router)
    .use(VueMatomo, {
        disableCookies: true,
        host: "https://matomo.reavo.dev",
        router,
        siteId: parseInt(import.meta.env.VITE_MATOMO_SITE_ID ?? 1)
    })

app.config.warnHandler = () => {}
app.mount("#app")

window._paq.push(["trackPageView"])
