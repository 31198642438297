import messages from "@intlify/unplugin-vue-i18n/messages"
import { createI18n } from "vue-i18n"

import deLocale from "@/locales/de.json"

export const i18n = createI18n<typeof deLocale, string>({
    legacy: false,
    locale: "de",
    messages
})
