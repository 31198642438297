import { defineStore } from "pinia"
import { computed, ref, watch } from "vue"

import type { Visitor } from "@/interfaces/profile.interface"
import type { Contact, Crush, ExtendedUser } from "@/interfaces/user.interface"

import { createSocketConnection } from "@/services/Socket.service"

import { useAuthStore } from "@/stores/authStore"

export const useUserStore = defineStore(
    "user",
    () => {
        const authStore = useAuthStore()

        const initUser = {
            active: false,
            age: 0,
            auth: { loginSecurity: true },
            avatar: "",
            channels: [],
            email: "",
            firstname: "",
            id: "",
            pronoun: "",
            registerStep: 0,
            username: ""
        }

        const user = ref<ExtendedUser>(initUser)

        const resetUser = () => {
            user.value = initUser
        }

        const visitors = ref<Visitor[] | undefined>()
        const visits = ref<Visitor[] | undefined>()
        const crushesSend = ref<Crush[] | undefined>()
        const crushesReceived = ref<Crush[] | undefined>()
        const loadVisitors = async () => {
            const { default: ProfileService } = await import("@/services/Profile.service")
            const response = await ProfileService.getVisitors()
            visits.value = response.visits
            visitors.value = response.visitors
            crushesReceived.value = response.crushesReceived
            crushesSend.value = response.crushesSend
        }

        const getCrushFromUser = computed(() => (userId: string) => {
            return crushesReceived.value?.find((crush) => crush.senderId === userId)
        })

        const getCrushForUser = computed(() => (userId: string) => {
            return crushesSend.value?.find((crush) => crush.receiverId === userId)
        })

        const role = ref<number>()

        const contacts = ref<Contact[]>([])
        const requests = ref<Contact[]>([])

        /******Friendships******/

        const friendships = computed<Contact[]>(() => {
            return contacts.value.filter((contact) => contact.friend)
        })

        const isFriend = computed(
            () => (userId: string) =>
                contacts.value.some((contact) => {
                    return contact.friend && contact.contactId === userId
                })
        )

        const isRequest = computed(
            () => (userId: string) =>
                contacts.value.some((contact) => {
                    return contact.friendRequest && contact.contactId === userId
                })
        )

        const hasFriendRequest = computed(
            () => (userId: string) =>
                requests.value.some((contact) => contact.friendRequest && contact.userId === userId)
        )

        const hasRelationshipRequest = computed(
            () => (userId: string) =>
                requests.value.some(
                    (contact) => contact.relationshipRequest && contact.userId === userId
                )
        )

        /******Socket******/

        const getContacts = () => {
            if (socket.value && connected.value) {
                socket.value.emit("contacts", {}, (response: { contacts: Contact[] }) => {
                    contacts.value = response.contacts
                })

                socket.value.emit("requests", {}, (response: { requests: Contact[] }) => {
                    requests.value = response.requests
                })
            }
        }

        const { socket, connected, closeSocket, initializeSocket } = createSocketConnection(
            "contact",
            {},
            getContacts
        )

        watch(
            () => authStore.authToken,
            () => {
                console.log("disconnect")
                closeSocket()
                if (authStore.authToken && user.value.active) {
                    initializeSocket()
                }
            },
            {}
        )

        return {
            closeSocket,
            contacts,
            crushesReceived,
            crushesSend,
            friendships,
            getContacts,
            getCrushForUser,
            getCrushFromUser,
            hasFriendRequest,
            hasRelationshipRequest,
            initializeSocket,
            isFriend,
            isRequest,
            loadVisitors,
            requests,
            resetUser,
            role,
            socket,
            user,
            visitors,
            visits
        }
    },
    {
        persist: [
            {
              pick: ["user"],
                storage: sessionStorage
            }
        ]
    }
)
