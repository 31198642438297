import type {
    NotificationSetting,
    NotificationType,
    SettingsResponse,
    SettingsTypesResponse
} from "@/interfaces/notification.interface"

import AbstractHttpService from "@/services/Abstract.http.service"

class NotificationHttpService extends AbstractHttpService {
    public async test(): Promise<void> {
        await this.get<void>("notification/test")
    }

    /*--------Get--------*/
    public async getNotificationTypes(): Promise<SettingsTypesResponse> {
        return (await this.get<SettingsTypesResponse>("notification/notificationTypes")).data
    }

    /*--------Put--------*/

    public async putPushToken(pushToken: string): Promise<void> {
        await this.put<void>("/notification/pushToken", { pushToken })
    }

    /*--------Patch--------*/

    public async patchSetting(setting: NotificationSetting): Promise<SettingsResponse> {
        return (await this.patch<SettingsResponse>("notification/setting", setting)).data
    }
}

export default new NotificationHttpService()
