<script setup lang="ts">
import LottieComp from "@/components/lottie/LottieComp.vue"

defineProps<{ email: string }>()
</script>

<template>
    <section class="cursor-default">
        <LottieComp animation-path="/assets/animations/emailSent.lottie.json" classes="w-96 h-96" />
        <h1 class="-mt-4 text-primary text-2xl">Bitte bestätige deine E-Mail-Adresse</h1>
        <p class="text-gray-600 max-w-xl text-l mx-auto mt-2">
            Wir haben dir eine E-Mail zukommen lassen, um deine angegebene E-Mail-Adresse
            <a class="text-primary">{{ email }}</a> zu bestätigen. Solltest du die E-Mail nicht
            finden können, so kontrolliere bitte auch deinen Spam-Ordner oder kontaktiere unseren
            Support.
        </p>
    </section>
</template>

<style scoped></style>
