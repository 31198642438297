<script setup lang="ts">
import { useInfoStore } from "@/stores/infoStore"

const info = useInfoStore()
</script>

<template>
    <div
        class="absolute top-14 right-0 bottom-14 p-4 z-10 w-56 overflow-hidden pointer-events-none select-none">
        <transition-group name="list" class="relative h-full" tag="ul">
            <div
                v-for="message in info.messages"
                :key="message.id"
                class="relative w-48 rounded flex flex-col px-4 py-2 pb-4 my-2 shadow pointer-events-auto cursor-pointer hover:scale-105"
                :class="{
                    'bg-red-500': message.type === 'error',
                    'bg-orange-500': message.type === 'warn',
                    'bg-green-500': message.type === 'success',
                    'bg-info-500': message.type === 'info'
                }"
                @click="info.removeMessage(message)">
                <div class="font-bold text-medium">{{ message.title }}</div>
                <div class="text-sm">{{ message.message }}</div>
                <div
                    v-if="!message.keep"
                    class="absolute bottom-0 left-0 right-0 h-2 flex justify-end">
                    <div
                        class="h-full w-full bg-gray-50/75 transition-max-width rounded-l"
                        :style="{
                            maxWidth:
                                Math.floor(
                                    100 -
                                        ((info.currentMillis - message?.timeStamp) /
                                            info.messageTTL) *
                                            100
                                ) + '%'
                        }" />
                </div>
            </div>
        </transition-group>
    </div>
</template>

<style scoped lang="scss">
.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.3s ease;
}

.list-enter-from {
    opacity: 0;
    transform: translate(50px, -50px);
}
.list-leave-to {
    opacity: 0;
    transform: translate(50px, 0px);
}

.list-leave-active {
    position: absolute;
}
</style>
