<script setup lang="ts">
import { watch } from "vue"
import { useI18n } from "vue-i18n"
import { RouterView } from "vue-router"

import NotificationComp from "@/components/notification/NotificationComp.vue"

import { useConfigStore } from "@/stores/configStore"

const config = useConfigStore()

const { locale } = useI18n({ useScope: "global" })

watch(
    () => config.locale,
    (newLocale) => {
        locale.value = newLocale
    },
    { immediate: true }
)

// import { useChatStore } from "@/stores/chatStore"
// import { useUserStore } from "@/stores/userStore"
// import { useWallStore } from "@/stores/wallStore"
//
// const chatStore = useChatStore()
// const wallStore = useWallStore()
// const userStore = useUserStore()
//
// onUnmounted(() => {
//     chatStore.closeSocket()
//     wallStore.closeSocket()
//     userStore.closeSocket()
// })
</script>

<template>
    <div>
        <router-view name="hint" />
        <router-view name="navBar" />
        <router-view name="sideBar" />
        <NotificationComp />
        <main
            class="pt-16 bg-pastel-blue-100 dark:bg-gray-900 dark:text-white relative min-h-screen select-none">
            <router-view v-slot="{ Component, route }">
                <transition :name="route.meta.transition as string" mode="out-in">
                    <Suspense>
                        <component :is="Component" :key="route.path" />
                    </Suspense>
                </transition>
            </router-view>
        </main>
        <router-view name="footer" />
    </div>
</template>

<style>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    transition: transform 0.25s ease;
}

.slide-left-enter,
.slide-left-leave-to {
    transform: translateX(100%);
}

.slide-left-leave,
.slide-left-enter-to {
    transform: translateX(0);
}

.slide-right-enter,
.slide-right-leave-to {
    transform: translateX(-100%);
}

.slide-right-leave,
.slide-right-enter-to {
    transform: translateX(0);
}
</style>
