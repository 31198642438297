import { decode, encode } from "base64-arraybuffer"

import type {
    FidoAuthenticateOptions,
    LoginStep1,
    LoginStep2,
    LoginUser,
    PublicKeyCredential,
    RegistrationFormData,
    RequestPayload,
    ResetPasswordFormData
} from "@/interfaces/auth.interface"
import type { AxiosResponseReturn } from "@/interfaces/basic.interface"
import type {
    FidoAuthenticator,
    PatchEmailDto,
    PatchLoginSecurityDto,
    PatchPasswordDto
} from "@/interfaces/settings.interface"

import AbstractHttpService from "@/services/Abstract.http.service"
class AuthService extends AbstractHttpService {
    public loginEmail = async (email: string): Promise<AxiosResponseReturn<LoginStep1>> => {
        return await this.post<LoginStep1>("auth/email", { email })
    }

    public reLogin = async (): Promise<AxiosResponseReturn<LoginUser>> => {
        return await this.get<LoginUser>("auth/relogin")
    }

    // public online = async (): Promise<boolean> => {
    //     try {
    //         await this.get<void>("status")
    //         console.log("online")
    //         return true
    //     } catch (e) {
    //         console.log(e)
    //         return false
    //     }
    // }

    public loginPassword = async (
        email: string,
        password: string
    ): Promise<AxiosResponseReturn<LoginStep2 | LoginUser>> => {
        return await this.post<LoginStep2 | LoginUser>("auth/password", { email, password })
    }

    public loginTwoFactor = async (
        email: string,
        password: string,
        otp: string
    ): Promise<AxiosResponseReturn<LoginUser>> => {
        return await this.post<LoginUser>("auth/twoFactor", { email, otp, password })
    }

    public register = async (
        registerDto: RegistrationFormData
    ): Promise<AxiosResponseReturn<null>> => {
        return await this.put<null>("auth/register", registerDto)
    }

    public resetPassword = async ({
        password,
        token
    }: ResetPasswordFormData): Promise<AxiosResponseReturn<LoginUser>> => {
        return await this.patch<LoginUser>("auth/resetPassword", { password, token })
    }

    public forgotPassword = async (email: string): Promise<AxiosResponseReturn<null>> => {
        return await this.post<null>("auth/forgotPassword", { email })
    }

    public fidoRegisterOptions = async (): Promise<LoginStep1> => {
        const { data } = await this.post<LoginStep1>("auth/fido2/register")
        return data
    }

    public fidoRegister = async (password: string) => {
        const data = await this.fidoRegisterOptions()

        const credential: PublicKeyCredential | null = (await navigator.credentials.create({
            //@ts-ignore
            publicKey: this.decodePublicKeyCredentialCreationOptions(
                data.challenge as RequestPayload
            )
        })) as never as PublicKeyCredential

        if (credential) {
            const response = await this.post("auth/fido2/register/verify", {
                challenge: {
                    id: credential.id,
                    rawId: encode(credential.rawId),
                    response: {
                        attestationObject: encode(
                            //@ts-ignore
                            credential.response.attestationObject as ArrayBuffer
                        ),
                        clientDataJSON: encode(credential.response.clientDataJSON)
                    },
                    type: credential.type
                },
                challengeId: data.challengeId,
                password
            })

            console.log("Registrierung:", response)
        }
    }

    public fidoAuthenticateRaw = async (): Promise<FidoAuthenticateOptions> => {
        const { data } = await this.get<{ challenge: RequestPayload; challengeId: string }>(
            "auth/fido2/authenticate"
        )

        if (!data.challenge) throw new Error("error")

        const assertion: PublicKeyCredential | null = (await navigator.credentials.get({
            publicKey: this.decodePublicKeyCredentialRequestOptions(
                data.challenge as RequestPayload
            )
        })) as PublicKeyCredential

        if (!assertion) throw new Error("error")

        return {
            challenge: {
                id: assertion.id,
                //@ts-ignore
                rawId: encode(assertion.rawId),
                response: {
                    authenticatorData: encode(assertion.response.authenticatorData as ArrayBuffer),
                    //@ts-ignore
                    clientDataJSON: encode(assertion.response.clientDataJSON),
                    signature: encode(assertion.response.signature as ArrayBuffer),
                    userHandle: assertion.response.userHandle
                        ? encode(assertion.response.userHandle as ArrayBuffer)
                        : null
                },
                type: assertion.type
            },
            challengeId: data.challengeId
        }
    }

    public fidoAuthenticate = async (email: string): Promise<LoginUser> => {
        const { data } = await this.loginEmail(email)
        const assertion: PublicKeyCredential | null = (await navigator.credentials.get({
            publicKey: this.decodePublicKeyCredentialRequestOptions(
                data.challenge as RequestPayload
            )
        })) as PublicKeyCredential

        if (!assertion) throw new Error("error")

        const response = await this.post<LoginUser>("auth/fido2/authenticate", {
            challenge: {
                id: assertion.id,
                rawId: encode(assertion.rawId),
                response: {
                    authenticatorData: encode(assertion.response.authenticatorData as ArrayBuffer),
                    clientDataJSON: encode(assertion.response.clientDataJSON),
                    signature: encode(assertion.response.signature as ArrayBuffer),
                    userHandle: assertion.response.userHandle
                        ? encode(assertion.response.userHandle as ArrayBuffer)
                        : null
                },
                type: assertion.type
            },
            challengeId: data.challengeId,
            email
        })
        return response.data
    }

    public getAllFido2Authenticators = async (): Promise<FidoAuthenticator[]> => {
        return (
            await this.get<{ fidoAuthenticators: FidoAuthenticator[] }>("auth/fido2/authenticators")
        ).data.fidoAuthenticators
    }

    public removeFido2Authenticator = async (
        authenticatorId: string
    ): Promise<FidoAuthenticator[]> => {
        return (
            await this.remove<{ fidoAuthenticators: FidoAuthenticator[] }>(
                "auth/fido2/authenticator/" + authenticatorId
            )
        ).data.fidoAuthenticators
    }

    public resendEmail = async (email: string) => {
        await this.post<void>("auth/resendEmail", { email })
    }

    public logout = async (): Promise<void> => {
        await this.remove<void>("auth")
    }

    public patchEmail = async (patchEmailDto: PatchEmailDto): Promise<void> => {
        await this.patch<void>("auth/email", patchEmailDto)
    }

    public patchPassword = async (patchPasswordDto: PatchPasswordDto): Promise<void> => {
        await this.patch<void>("auth/password", patchPasswordDto)
    }

    public patchLoginSecurity = async (
        patchLoginSecurityDto: PatchLoginSecurityDto
    ): Promise<void> => {
        await this.patch<void>("auth/loginSecurity", patchLoginSecurityDto)
    }

    private decodePublicKeyCredentialCreationOptions = (options: RequestPayload) => {
        return {
            ...options,
            challenge: decode(options.challenge),
            user: {
                ...options.user,
                id: decode(options.user.id)
            }
        }
    }

    private decodePublicKeyCredentialRequestOptions = (options: RequestPayload) => {
        return {
            ...options,
            allowCredentials: options.allowCredentials?.map((cred) => ({
                ...cred,
                id: decode(cred.id as never as string)
            })),
            challenge: decode(options.challenge)
        }
    }
}

export default new AuthService()
