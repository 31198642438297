<script>
import {CheckIcon} from "@heroicons/vue/24/outline"

export default {
    name: "Checkbox",
    data(){
        return{

        }
    },
    props:['checked','height','width'],
    components:{CheckIcon}
}
</script>

<template>
    <div :class="[checked?'bg-primary':'bg-white',height?`h-[${height}]`:'h-5',width?`w-[${width}]`:'w-5','rounded-md ring-1 p-0.5 duration-200 ring-primary cursor-pointer flex-shrink-0']">
        <CheckIcon class="text-white stroke-2"/>
    </div>
</template>

<style scoped>

</style>