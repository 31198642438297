<script setup lang="ts">
defineProps<{
    loading: boolean
    disabled?: boolean
    classnames?: string
}>()

defineEmits<{
    (e: "click"): void
}>()
</script>

<template>
    <button
        :class="classnames ?? 'btn btn-primary'"
        :disabled="loading || disabled"
        @click="$emit('click')">
        <slot v-if="loading" name="loader"> Lädt... </slot>
        <slot v-else />
    </button>
</template>
